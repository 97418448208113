import * as React from "react"

const isBrowser = typeof window !== 'undefined'

class IndexPage extends React.Component {
  componentWillMount() {
    if (isBrowser) {
      window.location = '/growth/same-day'
    }
  }
  render() {
     return (<p>redirect...</p>)
  }
}


export default IndexPage
